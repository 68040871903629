body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  height: 32px;
  width: auto;
}

#root {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

.bgi-no-repeat {
  background-repeat: no-repeat;
}
.bgi-size-cover {
  background-size: cover;
}
.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0;
}
.flex-root {
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex: 1 0 0px;
}
.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn.btn-primary:not(:disabled):not(.disabled).active, .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .show .btn.btn-primary.btn-dropdown, .show>.btn.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ee9d01;
  border-color: #ee9d01;
}
.btn.btn-primary.focus:not(.btn-text), .btn.btn-primary:focus:not(.btn-text), .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #ee9d01;
  border-color: #ee9d01;
}
.btn, .btn.disabled, .btn.disabled i, .btn.focus, .btn.focus i, .btn:disabled, .btn:disabled i, .btn:focus:not(.btn-text), .btn:focus:not(.btn-text) i, .btn:hover:not(.btn-text), .btn:hover:not(.btn-text) i, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled).active i, .btn:not(:disabled):not(.disabled):active:not(.btn-text), .btn:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn i, .show>.btn.dropdown-toggle, .show>.btn.dropdown-toggle i {
  -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
  transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
  transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
  transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #363537;
  background-color: #ef9e00;
  border-color: #e29500;
}
.btn.disabled, .btn:disabled, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-primary {
  color: #fff;
  background-color: #ffb423;
  border-color: #ffb423;
}
.btn:not(.btn-text) {
  cursor: pointer;
}
.btn.btn-pill {
  border-radius: 2rem;
}

.pl-15, .px-15 {
  padding-left: 3.75rem!important;
}
.pr-15, .px-15 {
  padding-right: 3.75rem!important;
}
.mt-10 {
  margin-top: 40px!important;
}
.opacity-40 {
  opacity: .4!important;
}
.display-1 {
  font-size: 2.125rem!important;
  line-height: 2.5rem;
  letter-spacing: .0073529412em!important;
}
#login_logo_sentia {
  width: 120px;
  height: auto !important;
}
.mb-5, .my-5 {
  margin-bottom: 20px!important;
}
.mt-7 {
  margin-top: 28px!important;
}
.mb-15, .my-15 {
  margin-bottom: 3.75rem!important;
}